// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './i18n/en.json';
import de from './i18n/de.json';

i18n
  .use(LanguageDetector) // Detects user language
  .use(initReactI18next)  // Passes i18n instance to react-i18next
  .init({
    lng: (new LanguageDetector().detect() || 'en').split('-')[0],
    fallbackLng: 'en',
    resources: {
      en: { translation: en },
      de: { translation: de },
    },
    detection: {
      order: ['navigator', 'htmlTag', 'querystring', 'cookie'],
      caches: ['cookie'],
    },
    interpolation: { escapeValue: false },
  });

export default i18n;
