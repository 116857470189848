// src/components/ParticleBackground.jsx

import React from 'react';
import Particles from 'react-tsparticles';

function ParticleBackground() {
  return (
    <Particles
      id="tsparticles"
      options={{
        particles: {
          move: {
            enable: true,
            speed: 1,
          },
          number: {
            value: 50,
          },
          size: {
            value: 2,
          },
          links: {
            enable: true,
          },
        },
      }}
    />
  );
}

export default ParticleBackground;
