import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import ProjectCard from './ProjectCard';
import ProjectModal from './ProjectModal';

function Skills({ projects }) {
  const { t } = useTranslation();
  const [showAllSkills, setShowAllSkills] = useState(false);
  const [expandedSkill, setExpandedSkill] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  const skillsData = [
    { name: 'ReactJS', level: 86, color: '#5CD0EE' },
    { name: 'JavaScript', level: 80, color: '#EAD41C' },
    { name: 'HTML', level: 90, color: '#D84924' },
    { name: 'CSS', level: 85, color: '#146CAD' },
    { name: 'Python', level: 76, color: '#F29D00' },
  ];

  const displayedSkills = showAllSkills ? skillsData : skillsData.slice(0, 4);

  const toggleSkillExpansion = (skillName) => {
    setExpandedSkill(expandedSkill === skillName ? null : skillName);
    setCurrentProjectIndex(0); // Reset index on skill toggle
  };

  const getProjectsForSkill = (skillName) => {
    const skillProjects = projects.filter((project) => project.tags.includes(skillName));
    return skillProjects;
  };

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  const currentProjects = (skillName) => {
    const skillProjects = getProjectsForSkill(skillName);
    return skillProjects.slice(currentProjectIndex, currentProjectIndex + 3); // Show 3 projects at a time
  };

  const nextProject = (skillName) => {
    const skillProjects = getProjectsForSkill(skillName);
    setCurrentProjectIndex((currentProjectIndex + 1) % skillProjects.length);
  };

  const prevProject = (skillName) => {
    const skillProjects = getProjectsForSkill(skillName);
    setCurrentProjectIndex(
      (currentProjectIndex - 1 + skillProjects.length) % skillProjects.length
    );
  };

  return (
    <section id='skills' className={`skills-container ${inView ? 'fade-in' : ''}`} ref={ref}>
      <div className="skills-content">
        <div className="skills-left">
          <div className="chip">{t('SKILLS.CHIP')}</div>
          <h2>{t('SKILLS.TITLE')}</h2>
          <p>{t('SKILLS.DESCRIPTION')}</p>
        </div>

        <div className="skills-right">
          {displayedSkills.map((skill) => (
            <div key={skill.name} className="skill-container">
              <div className="skill-row">
                <div
                  className="skill-bar"
                  onClick={() => toggleSkillExpansion(skill.name)}
                  style={{ background: `linear-gradient(to right, ${skill.color} ${skill.level}%, #ddd ${skill.level}%)` }}
                >
                  <span className="skill-name">{skill.name}</span>
                </div>
                <span className="skill-level">{skill.level}%</span>
              </div>
              {expandedSkill === skill.name && (
                <div className="project-gallery expanded">
                  <button className="prev-project" onClick={() => prevProject(skill.name)}>❮</button>
                  {currentProjects(skill.name).map((project) => (
                    <ProjectCard
                      key={project.id}
                      project={project}
                      onClick={() => handleProjectClick(project)}
                      size="small"
                    />
                  ))}
                  <button className="next-project" onClick={() => nextProject(skill.name)}>❯</button>
                </div>
              )}
            </div>
          ))}
          <button onClick={() => setShowAllSkills(!showAllSkills)}>
            {showAllSkills ? t('SKILLS.VIEW_LESS') : t('SKILLS.VIEW_ALL')}
          </button>
        </div>
      </div>
      {selectedProject && (
        <ProjectModal project={selectedProject} onClose={() => setSelectedProject(null)} />
      )}
    </section>
  );
}

export default Skills;
