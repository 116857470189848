import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

function AboutMe({ experience }) {
  const { t } = useTranslation();
  const [isEducationExpanded, setEducationExpanded] = useState(false);
  const [isExperienceExpanded, setExperienceExpanded] = useState(false);
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  const renderDataRows = (data, isExpanded) => (
    <>
      {data.slice(0, isExpanded ? data.length : 3).map((item, index) => (
        <div className="data-row" key={index}>
          <span className="date">
            {item.start_year} - {item.end_year || t('ABOUTME.PRESENT')}
          </span>
          <span className="title">{item.title}</span>
          <span className="description">{item.description}</span>
        </div>
      ))}
    </>
  );

  const educationData = experience.filter((item) => item.type === 'education');
  const workData = experience.filter((item) => item.type === 'work');

  return (
    <section id="about" className={`about-me ${inView ? 'fade-in' : ''}`} ref={ref}>
      <div className="glass-background">
        <div className="chip">{t('ABOUTME.CHIP')}</div>
        <h2 className="title">{t('ABOUTME.TITLE')}</h2>

        <div className="columns">
          <div className="column education">
            <h3>{t('ABOUTME.EDUCATION')}</h3>
            {renderDataRows(educationData, isEducationExpanded)}
            <button onClick={() => setEducationExpanded(!isEducationExpanded)}>
              {isEducationExpanded ? t('ABOUTME.COLLAPSE') : t('ABOUTME.EXPAND')}
            </button>
          </div>

          <div className="column experience">
            <h3>{t('ABOUTME.EXPERIENCE')}</h3>
            {renderDataRows(workData, isExperienceExpanded)}
            <button onClick={() => setExperienceExpanded(!isExperienceExpanded)}>
              {isExperienceExpanded ? t('ABOUTME.COLLAPSE') : t('ABOUTME.EXPAND')}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutMe;
