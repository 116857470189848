import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function ContactForm() {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    gender: '',
    name: '',
    email: '',
    phone: '',
    inquiryType: '',
    message: '',
  });
  const [statusMessage, setStatusMessage] = useState('');
  const [status, setStatus] = useState(''); // "success" or "error" for feedback styling

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
  
    fetch(`${process.env.REACT_APP_API_URL}/api/contact.php`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setStatus('success');
          setStatusMessage('Thank you! Your message has been sent.');
          setFormData({ gender: '', name: '', email: '', phone: '', inquiryType: '', message: '' });
        } else {
          setStatus('error');
          setStatusMessage('Oops! Something went wrong. Please try again later.');
        }
      })
      .catch((error) => {
        setStatus('error');
        setStatusMessage('Error submitting form. Please check your internet connection.');
        console.error('Error submitting contact form:', error);
      });
  };

  // Update form data on input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <section id="contact" className="contact-form">
      <h2>{t('CONTACT.TITLE')}</h2>
      <form onSubmit={handleSubmit}>
        <label>
          {t('CONTACT.GENDER')}:
          <select name="gender" value={formData.gender} onChange={handleChange}>
            <option value="">{t('CONTACT.SELECT')}</option>
            <option value="male">{t('CONTACT.MALE')}</option>
            <option value="female">{t('CONTACT.FEMALE')}</option>
          </select>
        </label>

        <label>
          {t('CONTACT.NAME')}:
          <input type="text" name="name" required value={formData.name} onChange={handleChange} />
        </label>

        <label>
          E-Mail:
          <input type="email" name="email" required value={formData.email} onChange={handleChange} />
        </label>

        <label>
          {t('CONTACT.PHONENBR')}:
          <input type="tel" name="phone" value={formData.phone} onChange={handleChange} />
        </label>

        <label>
          {t('CONTACT.INQUIRYTYPE')}:
          <select name="inquiryType" required value={formData.inquiryType} onChange={handleChange}>
            <option value="">{t('CONTACT.SELECT')}</option>
            <option value="hiring">{t('CONTACT.HIRING')}</option>
            <option value="freelance">{t('CONTACT.FREELANCE')}</option>
            <option value="other">{t('CONTACT.OTHER')}</option>
          </select>
        </label>

        <label>
          {t('CONTACT.MESSAGE')}:
          <textarea name="message" required value={formData.message} onChange={handleChange}></textarea>
        </label>

        <button type="submit">{t('CONTACT.SEND')}</button>
      </form>
      {statusMessage && (
        <p className={`status-message ${status}`}>{statusMessage}</p>
      )}
    </section>
  );
}

export default ContactForm;
