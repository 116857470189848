import React from 'react';

function ProjectCard({ project, onClick, size = "small" }) {
  return (
    <div className={`project-card ${size}`} onClick={onClick}>
      <img src={project.thumbnail} alt={project.title} />
      <div className="project-info">
        <h3>{project.title}</h3>
        <p>{project.shortDescription}</p>
      </div>
    </div>
  );
}

export default ProjectCard;
